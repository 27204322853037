import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import {
  Button,
  Spinner,
  Badge,
  Nav,
  NavItem,
  NavLink,
  InputGroup,
  Input,
  Form
} from 'reactstrap';
import { Link } from 'react-router-dom';
import constants from '../../lib/constants';
import { _hasAccess } from '../../middleware/accessMiddleware'
import ConfirmModal from '../../components/notificationModal/ConfirmModal';

class index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuCode: constants.MENU_MANAGE_MENU,
      scope: [],
      columns: [
        {
          name: 'ID',
          selector: 'id',
          sortable: true
        },
        {
          name: 'Name',
          selector: 'name',
          sortable: true
        },
        {
          name: 'Group',
          selector: 'group_name',
          sortable: true
        },
        {
          name: 'Order',
          sortable: true,
          cell: data =>
            <div>
              {data.menu_order || data.group_order ? data.group_order + '.' + (data.menu_order ?? 0) : '-'}
            </div>
        },
        {
          name: 'Status',
          selector: 'status',
          sortable: false,
          cell: data =>
            <div>
              {data.status ?
                <Badge
                  color="success"
                  pill
                >
                  Active
                </Badge>
                :
                <Badge
                  color="danger"
                  pill
                >
                  Non-Active
                </Badge>
              }
            </div>
        },
        {
          name: 'Action',
          selector: 'action',
          sortable: false,
          cell: role =>
            <div>
              <Link to={`/menu/${role.id}`}>
                <button className="text-dark btn btn-sm btn-outline-link" ><li className="fa fa-eye"></li></button>
              </Link>
              {
                this.state.scope.includes('update') ?
                  <Link to={`/menu/edit/${role.id}`}>
                    <button className="text-primary btn btn-sm btn-outline-link" ><li className="fa fa-edit"></li></button>
                  </Link>
                  : null
              }
              {
                this.state.scope.includes('delete') ?
                  <button className="text-danger btn btn-sm btn-outline-link" onClick={() => this.showConfirmDelete(role.id)}><li className="fa fa-trash"></li></button>
                  : null
              }
            </div>
        }
      ],
      loaderPage: true,
      data: [],
      editData: {},
      id: null,
      showTable: false,
      showModal: false,
      showModalEdit: false,
      disableButtonModal: false,
      alert: {
        show: false,
        status: 'success',
        message: ''
      },
      paginationPerPage: 5,
      paginationTotalRows: 0,
      paginationPage: 1,
      group: 'dashboard_app',
      search: ''
    };
    // this.modalUser = this.modalUser.bind(this);
  }

  componentDidMount() {
    this.checkAccess();
    this.getList();
    this.setState({
      loaderPage: false
    })
  }


  changeType(type) {
    this.setState({
      group: type,
      search: '',
    }, () => {
      this.getList();
    })
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, 'read');
    console.log(access)
    if (access) {
      let scope = [];
      access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

      this.setState({
        scope: scope
      })
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  getList() {
    API.get('role/menu/list?size='
      + this.state.paginationPerPage
      + '&page=' + this.state.paginationPage
      + '&group=' + this.state.group
      + '&search=' + this.state.search
      , { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then(res => {
        const result = res.data;
        this.setState({
          data: result.data.item,
          paginationTotalRows: result.data.totalItem,
          showTable: true
        })
        console.log(result)
      })
      .catch(err => {
        console.log("Error", err)
      })
  }

  changeEditData(body) {
    this.setState({ editData: body });
  }

  showConfirmDelete = (id) => {
    this.setState({
      showModal: !this.state.showModal,
      id: id,
    });
  }

  afterSubmit(status, message) {
    this.getList()

    this.setState({
      alert: {
        show: true,
        status: status,
        message: message
      }
    });

    setTimeout(() => {
      this.setState({
        alert: {
          show: false,
          status: '',
          message: ''
        }
      });
    }, 3000)
  }

  changePage(page) {
    this.setState({
      paginationPage: page
    }, () => {
      this.getList();
    })
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState({
      paginationPerPage: rowsPerPage
    }, () => {
      this.getList();
    })
  }

  onSearch = (e) => {
    e.preventDefault();
    this.setState({
      paginationPage: 1
    }, () => {
      this.getList()
    })
  }

  render() {
    return (
      !this.state.showTable ? <Spinner style={{ marginTop: '30%', marginLeft: '50%' }}>Loading</Spinner> :
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-tabs">
                    <strong className="card-title">Manage Menu</strong>
                    <div className="float-right">
                      {
                        this.state.scope.includes('create') ?
                          <Link to="/menu/add">
                            <Button
                              color="primary"
                              size="sm"
                            >
                              Add Menu
                            </Button>
                          </Link>
                          : null
                      }
                    </div>
                    <Nav tabs className='mt-3'>
                      <NavItem>
                        <NavLink
                          active={this.state.group === 'dashboard_app' ? true : false}
                          className='fs-13 cursor-pointer'
                          onClick={() => this.changeType('dashboard_app')}
                        >
                          Backoffice
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={this.state.group === 'dashboard_partner' ? true : false}
                          className='fs-13 cursor-pointer'
                          onClick={() => this.changeType('dashboard_partner')}
                        >
                          Partner
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <div className="card-body">
                    <Form className='d-flex justify-content-between mb-3' onSubmit={(e) => this.onSearch(e)}>
                      <InputGroup className='w-auto'>
                        <Input
                          placeholder='Search Name'
                          onChange={(e) => this.setState({ search: e.target.value })}
                          value={this.state.search}
                        />
                        <Button color="primary">
                          <i className="fa fa-search"></i>
                        </Button>
                      </InputGroup>
                    </Form>
                    {
                      // !this.state.showTable ? <>Loading ...</> :
                      <DataTable
                        noHeader={true}
                        columns={this.state.columns}
                        data={this.state.data}
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                        paginationTotalRows={this.state.paginationTotalRows}
                        onChangePage={page => this.changePage(page)}
                        onChangeRowsPerPage={rowsPerPage => this.changeRowsPerPage(rowsPerPage)}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            open={this.state.showModal}
            close={() => this.showConfirmDelete()}
            id={this.state.id}
            url={`role/menu/${this.state.id}`}
          />
        </div>
    )
  }
}

export default index;