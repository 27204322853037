import React, { Component } from "react";
import {
  Modal, ModalHeader, ModalBody,
  Spinner,
  Input
} from 'reactstrap';
import API from "../../../api";

const init_body = {
  name: null,
  order_number: null
}

export default class GroupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.open,
      error: {},
      buttonLoading: false,
      buttonLoadingAdd: false,
      newGroup: '',
      body: init_body,
      editTab: false,
      showAdd: false
    }
  }

  componentDidMount() {

  }

  onDelete(id, index) {
    this.setState({ buttonLoading: true });

    API.delete(`role/menu/group/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;

        const newList = this.props.data;

        if (result.status) {
          newList.splice(index, 1);
          this.props.setData(newList)
          this.props.setNotif(true, 'Success', 'Group menu updated successfully')
        }
        else {
          this.props.setNotif(true, 'Failed', result.message)
        }

        setTimeout(() => {
          this.props.setNotif(false)
        }, 3000)
      })
      .catch(err => {
        alert(err)
      })
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });
  }

  onEdit(id, index, body) {
    this.setState({
      buttonLoading: true,
      // notif: { show: true, title: 'Processing ...' }
    });

    const bodyNew = {
      name: body.name ?? this.props.data[index].name,
      order_number: body.order_number ?? this.props.data[index].order_number
    }
    // alert(JSON.stringify(body)); return;
    API.put(`role/menu/group/${id}`, bodyNew, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.props.setNotif(true, 'Success', 'Group menu updated successfully')

        this.setState({
          buttonLoading: false
        });

        // this.props.close()

        setTimeout(() => {
          this.props.setNotif(false)
        }, 1300);

      })
      .catch(err => {
        this.setState({
          body: {},
          notif: {
            title: 'Failed',
            message: err,
          }
        });
      })
  }

  onAdd(body) {
    this.setState({
      buttonLoadingAdd: true,
    });

    const bodyNew = {
      name: body.name,
      order_number: body.order_number,
      role_group_name: this.props.value.role_group_name
    }
    
    // alert(JSON.stringify(body)); return;
    API.post(`role/menu/group`, bodyNew, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        this.props.setNotif(true, 'Success', 'Group menu updated successfully')

        this.setState({
          buttonLoadingAdd: false,
          showAdd: false
        });
      })
      .catch(err => {
        this.props.setNotif(true, 'Failed', 'Failed to update group menu')
      })

    setTimeout(() => {
      this.props.setNotif(false)
    }, 1300);
  }

  onClose() {
    this.setState({
      showModal: false,
      showAdd: false
    })
    this.props.close && this.props.close()
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.onClose()} size="md">
          <ModalHeader>
            Group Menu
          </ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='col-md-12'>
                {
                  this.props.data.length > 0
                    ?
                    <div className='table-modal-wrapper'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>Group Name</th>
                            <th>Sequence</th>
                            <th className='text-right'>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.props.data.map((item, index) => (
                              <RowComponent
                                key={index}
                                data={item}
                                value={this.state.body.name}
                                order_number={this.state.body.order_number}
                                onEdit={(body) => this.onEdit(item.id, index, body)}
                                onDelete={() => this.onDelete(item.id, index)}
                                buttonLoading={this.state.buttonLoading}
                              />
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                    :
                    null
                }
                {
                  this.state.showAdd ?
                    <div className='table-modal-wrapper'>
                      <table className='table'>
                        <tbody>
                          <RowComponent
                            onAdd={(body) => this.onAdd(body)}
                            onCancel={() => this.setState({ showAdd: false })}
                            buttonLoading={this.state.buttonLoadingAdd}
                            editTab={true}
                          />
                        </tbody>
                      </table>
                    </div>
                    :
                    <div className=' col-md-2'>
                      <button className='position-relative btn btn-info button-add-variant-modal mt-3' onClick={() => this.setState({ showAdd: !this.state.showAdd })}>
                        {!this.state.buttonLoadingAdd ? <><i className="fa fa-plus mr-2"></i>New Group</> : <Spinner size="sm">Loading...</Spinner>}
                      </button>
                    </div>
                }
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

class RowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editTab: this.props.editTab ?? false,
      error: {},
      body: {
        name: this.props.name ?? this.props.data?.name,
        order_number: this.props.order_number ?? this.props.data?.order_number
      },
    }
  }

  onSubmit() {
    this.props.onEdit && this.props.onEdit(this.state.body);
    this.props.onAdd && this.props.onAdd(this.state.body);
    this.setState({ editTab: false })
  }

  onChange = (e) => {
    const newBody = this.state.body
    newBody[e.target.name] = e.target.value

    const error = this.state.error;
    if (!e.target.value) {
      error[e.target.name] = 'Field is Required'
    }
    else {
      delete error[e.target.name]
    }

    this.setState({
      body: newBody,
      error: error
    });
  }

  onCancel() {
    this.setState({ editTab: false })
    this.props.onCancel && this.props.onCancel();
  }

  render() {
    return (
      <>
        <tr id="box">
          <td className='align-middle' width={'40%'}>
            <span hidden={this.state.editTab}>
              {this.props.data?.name}
            </span>
            <div className="d-flex gap-2">
              <Input
                name="name"
                placeholder="Enter group name"
                onChange={(e) => this.onChange(e)}
                hidden={!this.state.editTab}
                value={this.state.body.name}
              />
            </div>
          </td>
          <td width={'20%'}>
            <span hidden={this.state.editTab}>{this.props.data?.order_number}</span>
            <Input
              style={{ width: '70px' }}
              name="order_number"
              placeholder=""
              onChange={(e) => this.onChange(e)}
              hidden={!this.state.editTab}
              value={this.state.body.order_number}
            />
          </td>
          <td className='text-right text-sm' width={'40%'}>
            <button hidden={this.state.editTab} onClick={() => this.setState({ editTab: true })} className='btn btn-xs btn-link'><i className="fa fa-edit"></i></button>
            <button hidden={this.state.editTab} onClick={() => this.props.onDelete()} className='btn btn-xs btn-link ml-2 text-danger'><i className="fa fa-trash"></i></button>
            <div className="d-flex">
              <button type="button" hidden={!this.state.editTab} onClick={() => this.setState({ editTab: false })} className='text-muted btn btn-xs btn-link ml-2 text-decoration-none'>Cancel</button>
              <button hidden={!this.state.editTab} onClick={() => this.onSubmit()} className='btn btn-xs btn-primary'>
                {!this.props.buttonLoading ? 'Save' : <Spinner size="sm">Loading...</Spinner>}
              </button>
            </div>
          </td>
        </tr>
      </>
    );
  };

}