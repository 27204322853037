import React, { Component } from "react";
import API from "../../api";
import API_TOKEN from "../../apitoken";
import DataTable from "react-data-table-component";
import { CustomInput } from "reactstrap";
import LoadingBar from "react-top-loading-bar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { addLogUsers } from "../../helpers/log";
import { _hasAccess } from "../../middleware/accessMiddleware";

export default class GenerateQRCode extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.getManufacturerOption = this.getManufacturerOption.bind(this);

    this.state = {
      menuCode: "create-token",
      menuId: null,
      scope: [],
      columns: [
        {
          name: "Id",
          selector: "id",
          sortable: true,
          maxWidth: "30px",
        },
        {
          name: "Init",
          selector: "init_data",
          sortable: true,
        },
        {
          name: "Checking",
          selector: "checking_data",
          sortable: true,
        },
        {
          name: "Inserting",
          selector: "insert_data",
          sortable: true,
        },
        {
          name: "Status",
          selector: "status_formated",
          sortable: true,
        },
        {
          name: "Created at",
          selector: "created_at_formated",
          sortable: true,
        },
        {
          name: "Duration",
          selector: "duration",
          sortable: true,
        },
        {
          name: "Qty",
          selector: "qty",
          sortable: true,
          cell: (data) => parseInt(data.init_to) - parseInt(data.init_from) + 1,
        },
        {
          name: "Manufacture",
          selector: "manufacturer_name",
          sortable: true,
        },
        {
          name: "QRRange",
          selector: "qrrange",
          sortable: true,
        },
        {
          name: "Action",
          selector: "action",
          sortable: false,
          cell: (data) => (
            <div>
              {data.status === 1 ? (
                <button
                  type="button"
                  className="btn btn-sm btn-outline-danger"
                  onClick={this.downloadCSV.bind(this, data)}
                >
                  <li className="fa fa-download"></li> Download
                </button>
              ) : null}
            </div>
          ),
        },
      ],
      productCode: "",
      manufacturerId: "",
      madeIn: "",
      productionDate: "",
      expiredDate: "",
      manufacturer: [],
      countToken: 0,
      currentToken: 0,
      paginationPerPage: 10,
      paginationTotalRows: 0,
      paginationPage: 1,
      progressToken: null,
      disabled: false,
      customUrl: "",
      secureQr: 0,
    };
  }

  componentDidMount() {
    this.checkAccess();
    this.getManufacturer();
    this.getHistoryGenerateToken();
  }

  async checkAccess() {
    const access = await _hasAccess(this.state.menuCode, "create");
    if (access) {
      let scope = [];
      access.scope.map((item) =>
        item.status == 1 ? scope.push(...[item.name]) : null
      );

      this.setState({
        menuId: access.id,
        scope: scope,
      });
      return;
    }

    this.props.history.push(`/unauthorized`);
  }

  async getManufacturer() {
    let manufacturerOptions = await API.get("manufacturer/options", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });

    this.setState({
      manufacturer: manufacturerOptions.data,
    });
  }

  getManufacturerOption() {
    let items = [];
    let manufacturer = this.state.manufacturer;
    console.log("List manufacturer", manufacturer);
    for (let i = 0; i < manufacturer.length; i++) {
      items.push(
        <option
          key={manufacturer[i].manufacturer_id}
          value={manufacturer[i].manufacturer_id}
        >
          {manufacturer[i].name}
        </option>
      );
    }
    return items;
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeDate(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  downloadCSV(data) {
    const body = {
      from: data.init_from,
      to: data.init_to,
    };
    API.post("/qrcode/export-csv-new", body, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((response) => {
        console.log("ini response download csv", response);
        window.open(response.data.data.linkCSV, "_blank");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
  getHistoryGenerateToken() {
    API.get(
      "history-token?size=" +
        this.state.paginationPerPage +
        "&page=" +
        this.state.paginationPage,
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    )
      .then((res) => {
        console.log("List history", res.data.data.item);
        const data = res.data.data;
        this.setState({
          data: data.item,
          paginationTotalRows: data.totalItem,
        });
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }
  searchQRCode(e) {
    API.get(
      "history-token?size=" +
        this.state.paginationPerPage +
        "&page=" +
        this.state.paginationPage +
        "&search=" +
        this.state.search,
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    )
      .then((res) => {
        console.log("List history", res.data.data.item);
        const data = res.data.data;
        this.setState({
          data: data.item,
          paginationTotalRows: data.totalItem,
        });
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }

  changePage(page) {
    this.setState(
      {
        paginationPage: page,
      },
      () => {
        this.getHistoryGenerateToken();
      }
    );
  }

  changeRowsPerPage(rowsPerPage) {
    this.setState(
      {
        paginationPerPage: rowsPerPage,
      },
      () => {
        this.getHistoryGenerateToken();
      }
    );
  }

  handleSubmit(event) {
    const MySwal = withReactContent(Swal);
    this.setState({ disabled: true });
    console.log("submit", this.state.countToken);
    // if countToken less than 1000 qr code then create alert and return
    if (this.state.countToken < 1000) {
      alert("Minimal generate token 1000");
      this.setState({ disabled: false });
      return;
    }
    let customUrl = "-";
    if (this.state.customUrl !== "") customUrl = this.state.customUrl;

    let body = {
      queueName: "messages-broker",
      payload: {
        token: this.state.countToken,
        customUrl: customUrl,
        manufacturerID: this.state.manufacturerId,
      },
    };
    this.LoadingBar.continuousStart();
    API_TOKEN.post("generate-token", body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        secure_qr: this.state.secureQr === 1 ? true : false,
      },
    })
      .then((data) => {
        console.log("ini data, ", data.data);
        alert(data.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ disabled: false });
    this.LoadingBar.complete();

    // console.log("Param token", body);
    // if(this.state.countToken > 100){
    //     // var indexForeach = [];
    //     // for(var x = 0; x < splitToken; x++){
    //     //     indexForeach.push(x)
    //     // }
    //     var i = 0;
    //     this.splitToken(i);
    //     // this.props.history.push("/");
    // } else {
    //     this.LoadingBar.continuousStart()
    //     API_TOKEN.post('token', body, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${localStorage.getItem('token')}`
    //         }
    //     })
    //     .then(response => {
    //         this.setState({disabled:false})
    //         this.LoadingBar.complete()
    //         const data = response.data;
    //         let from = data.insertId;
    //         let to = data.insertId + data.affectedRows;
    //         to = to-1;
    //         let listQR = [];
    //         from = parseInt(from, 10)

    //         for (var i = from; i <= to; i++){
    //             listQR.push(i);
    //         }
    //         let body = {
    //             listQR : listQR
    //         }
    //         MySwal.fire({
    //         title: <strong>Good job!</strong>,
    //         html: <i>Success create bulk token, Your Range Token Id = {from} - {to}</i>,
    //         icon: 'success',
    //         showCancelButton: true,
    //         cancelButtonText: 'Close',
    //         confirmButtonText: 'Download CSV',
    //         showLoaderOnConfirm: true,
    //         preConfirm: () => {
    //             return API.post('/qrcode/export-csv', body,  { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }})
    //             .then(response => {
    //                 this.LoadingBar.complete()
    //                 return response.data
    //             })
    //             .catch(error => {
    //                 console.log("Error",error);
    //             });
    //         },
    //         allowOutsideClick: () => !Swal.isLoading()
    //         }).then((result) => {
    //         if (result.isConfirmed) {
    //             setTimeout(() => {
    //                 window.open(result.value.linkCSV, "_blank")
    //             }, 500);
    //         }
    //         })
    //         // this.props.history.push("/");
    //     })
    //     .catch(error => {
    //         console.log("Error create bulk token",error);
    //     });
    // }
  }

  async splitToken(i) {
    const MySwal = withReactContent(Swal);
    i = i || 0;
    this.LoadingBar.continuousStart();
    var splitToken = this.state.countToken / 100;
    let bodySplit = {
      numberOfTokens: 100,
    };
    if (i < splitToken) {
      this.setState({
        currentToken: i,
        progressToken:
          "Processing " +
          parseInt(i + 1) +
          " from " +
          splitToken +
          " sequence tasks",
      });
      console.log("Param token " + i, bodySplit);
      await API_TOKEN.post("token", bodySplit, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then(async (response) => {
          this.LoadingBar.complete();
          console.log("Sukses create bulk token");
          await localStorage.setItem("lastTokenID", response.data.insertId);
          i++;
          await this.splitToken(i);
        })
        .catch((error) => {
          console.log("Error create bulk token", error);
        });
      // var This = this;
      // setTimeout(function() { This.splitToken(i) }, 180000);
    } else {
      console.log("ini total nya", localStorage.getItem("lastTokenID"));
      // this.props.history.push("/");
      const total = parseInt(localStorage.getItem("lastTokenID"), 10);
      let to = total + 100;
      let from = to - parseInt(this.state.countToken, 10);
      let listQR = [];
      to = to - 1;
      from = parseInt(from, 10);
      to = parseInt(to, 10);

      for (var u = from; u <= to; u++) {
        listQR.push(u);
      }
      let body = {
        listQR: listQR,
      };
      console.log("token = ", this.state.countToken);
      console.log(from, "-", to);
      await this.LoadingBar.complete();
      await this.setState({ disabled: false });
      await MySwal.fire({
        title: <strong>Good job!</strong>,
        html: (
          <i>
            Success create bulk token, Your Range Token Id = {from} - {to}
          </i>
        ),
        icon: "success",
        showCancelButton: true,
        cancelButtonText: "Close",
        confirmButtonText: "Download CSV",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return API.post("/qrcode/export-csv", body, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
            .then((response) => {
              this.LoadingBar.complete();
              return response.data;
            })
            .catch((error) => {
              console.log("Error", error);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          setTimeout(() => {
            window.open(result.value.linkCSV, "_blank");
          }, 500);
        }
      });
    }
  }

  startFetch = () => {
    this.LoadingBar.continuousStart();
  };

  onFinishFetch = () => {
    this.LoadingBar.complete();
  };

  render() {
    return (
      <div className="content">
        <LoadingBar onRef={(ref) => (this.LoadingBar = ref)} />
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Add Token & PIN</strong>
                </div>
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label for="productCode" className="control-label mb-1">
                        Manufacturer
                      </label>
                      <select
                        name="manufacturerId"
                        id="manufacturerId"
                        class="form-control"
                        onChange={this.handleChange}
                      >
                        {this.getManufacturerOption()}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="form-group has-success m-0">
                        <label for="productCode" className="control-label mb-1">
                          Number of tokens
                        </label>
                        <input
                          id="productCode"
                          name="countToken"
                          type="text"
                          className="form-control valid"
                          data-val="true"
                          data-val-required="Please enter Number of Tokens"
                          autocomplete="productCode"
                          aria-required="true"
                          aria-invalid="false"
                          aria-describedby="productCode"
                          value={this.state.countToken}
                          onChange={this.handleChange}
                        />
                        {
                          this.state.progressToken ? (
                            <p style={{ margin: "1em 0" }}>
                              {this.state.progressToken}
                            </p>
                          ) : null
                          // <p style={{ margin:'1em 0' }}>aaa</p>
                        }
                        <span
                          className="help-block field-validation-valid"
                          data-valmsg-for="productCode"
                          data-valmsg-replace="true"
                        ></span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="form-group has-success m-0">
                        <label for="customUrl" className="control-label mb-1">
                          Custom Url
                        </label>
                        <input
                          id="customUrl"
                          name="customUrl"
                          type="text"
                          className="form-control valid"
                          data-val="true"
                          data-val-required="Please enter Custom Url"
                          autocomplete="customUrl"
                          aria-required="true"
                          aria-invalid="false"
                          aria-describedby="customUrl"
                          value={this.state.customUrl}
                          onChange={this.handleChange}
                        />
                        <span
                          className="help-block field-validation-valid"
                          data-valmsg-for="productCode"
                          data-valmsg-replace="true"
                        ></span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="form-group has-success m-0">
                        <label for="customUrl" className="control-label mb-1">
                          Secure QR
                        </label>
                        <div style={{ marginTop: 5 }}>
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input form-switch form-switch"
                              type="checkbox"
                              value={this.state.secureQr}
                              checked={this.state.secureQr === 1}
                              role="switch"
                              id="flexSwitchCheckDefault"
                              onClick={() => {
                                this.setState({
                                  secureQr: this.state.secureQr === 1 ? 0 : 1,
                                });
                              }}
                            />
                            <label class="label-inline">
                              {this.state.secureQr === 1 ? "On" : "Off"}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <button
                        disabled={this.state.disabled}
                        type="button"
                        className="btn btn-info"
                        onClick={this.handleSubmit}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <p className="m-0 mt-1 color-black fs-13">
                    *QRCode Range cannot be less than 1.000 and greater than
                    2.000.000 data
                  </p>
                  <h3>History Generate Token</h3>
                  <div className="row">
                    <div className="col-md-4">
                      <input
                        id="search"
                        name="search"
                        type="text"
                        className="form-control valid"
                        data-val="true"
                        data-val-required="search"
                        autocomplete="search"
                        aria-required="true"
                        aria-invalid="false"
                        aria-describedby="search"
                        placeholder="Search by qrcode36 OR product_id"
                        value={this.state.search}
                        onChange={this.handleChange}
                        // style={{ textTransform: 'uppercase' }}
                      />
                    </div>
                    <div className="col-md-1">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-success w-50"
                        onClick={(e) => this.searchQRCode(e)}
                      >
                        <li className="fa fa-search"></li>
                      </button>
                    </div>
                    <div className="col-md-2" style={{ marginLeft: "-4.7%" }}>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary w-50"
                        onClick={this.getHistoryGenerateToken.bind(this)}
                      >
                        <li className="fa fa-reload"></li>Refresh
                      </button>
                    </div>
                  </div>
                  <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    pagination={true}
                    paginationServer={true}
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    paginationTotalRows={this.state.paginationTotalRows}
                    onChangePage={(page) => this.changePage(page)}
                    onChangeRowsPerPage={(rowsPerPage) =>
                      this.changeRowsPerPage(rowsPerPage)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
